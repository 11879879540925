<template>
  <div id="clueScan">
    <img class="title-bottom" :src="titlePic" alt="">
    <main v-if="successShow">
      <van-field v-model="params.customerName" name="名称" label="名称" placeholder="请输入名称" required
        :rules="[{ required: true, message: '请填写名称' }]" />
      <van-field v-model="params.customerMobile" type="tel" label="手机号" placeholder="请输入手机号" required maxlength="11" />
      <van-field v-model="params.vcode" center clearable label="短信验证码" placeholder="请输入验证码" required maxlength="6">
        <template #button>
          <van-button size="small" type="info" @click="onSentSms" :disabled="smsDisabled"
            style="width: 1rem;background: #1c509c;">{{
              smsBtnText }}</van-button>
        </template>
      </van-field>
      <van-field v-model="params.requiredAreaRangeName" name="requiredAreaRangeName" label="需求面积" placeholder="请选择客户需求面积"
        @click="requiredAreaRangeShow = true" readonly clickable required />
      <van-field v-model="params.remark" label="备注" placeholder="请输入备注（非必填）" />
      <img class="fore-word" :src="foreWord" alt="">
      <div style="margin: 16px">
        <van-button style="background: #1c509c;" type="info" block round @click="onSubmit"
          :disabled="submitDisabled">提交信息</van-button>
      </div>
      <img style="width: 100%;" :src="fuwuPic" alt="">
    </main>
    <div class="success-page" v-else>
      <van-icon name="checked" class="success-color" size="80" />
      <p>提交成功</p>
    </div>
    <!-- 需求面积 -->
    <van-popup v-model="requiredAreaRangeShow" round position="bottom">
      <van-picker show-toolbar @cancel="requiredAreaRangeShow = false" :columns="areaList"
        @confirm="onFinishRequiredAreaRangeShow" />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isPhone } from "@/utils/validate";
import { sentSms, customerByProject } from "@/api/clue/clueScan";
import {
  areaList,
} from "@/db/clue.js"
export default {
  data() {
    return {
      titlePic: require("../../assets/images/clue/title.png"),
      foreWord: require("../../assets/images/clue/foreword.jpg"),
      bgPic: require("../../assets/images/clue/bg.jpg"),
      fuwuPic: require("../../assets/images/clue/fuwu.jpg"),
      successShow: true,
      smsBtnText: "发送验证码",
      smsDisabled: false,
      params: {
        partnerId: this.$route.query.partnerId,
        projectId: this.$route.query.projectId,
        spId: this.$route.query.spId,
        recommendType: this.$route.query.recommendType,
        customerName: "",
        customerMobile: "",
        vcode: "",
        remark: "",
        industryId: 40,
        channelType: 5,
        requiredAreaRange: '',//需求面积
        requiredAreaRangeName: '',//需求面积
      },
      // 需求面积
      requiredAreaRangeShow: false,
      areaList,
    };
  },
  computed: {
    // 【监听】提交按钮
    submitDisabled() {
      return this.params.customerName &&
        isPhone(this.params.customerMobile) &&
        this.params.vcode
        ? false
        : true;
    },
  },
  methods: {
    // 【监听】需求面积
    onFinishRequiredAreaRangeShow(value) {
      this.requiredAreaRangeShow = false;
      this.params.requiredAreaRangeName = value.text;
      this.params.requiredAreaRange = value.value;
    },

    // 【监听】发送验证码
    onSentSms() {
      if (!isPhone(this.params.customerMobile))
        return Toast("请先输入正确的手机号");
      let time;
      let _this = this;
      sentSms(this.params.customerMobile).then((res) => {
        if (res.success) {
          Toast("短信发送成功");
          _this.smsDisabled = true;
          _this.smsBtnText = 30;
          time = setInterval(function () {
            if (_this.smsBtnText > 1) {
              _this.smsBtnText--;
            } else {
              _this.smsDisabled = false;
              _this.smsBtnText = "发送验证码";
              clearInterval(time);
            }
          }, 1000);
        } else {
          Toast(res.msg);
        }
      });
    },
    // 【监听】提交信息
    onSubmit() {
      if (!this.params.requiredAreaRange) return Toast("请选择需求面积");
      let data = this.params;
      customerByProject(data).then((res) => {
        if (res.success) {
          this.successShow = false;
          if (WeixinJSBridge) {
            setTimeout(() => {
              WeixinJSBridge.call("closeWindow");
            }, 2000);
          }
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#clueScan {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}

.success-page {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 0.22rem;
    padding: 0.2rem 0;
  }
}

.fore-word {
  width: 100%;
}

.title-bottom {
  border-bottom: 1px solid #eee;
  height: .5rem;
}
</style>